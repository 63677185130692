<script setup>
import Form from "~/components/atoms/Form.vue";
import FormInput from "~/components/atoms/FormInput.vue";
import Heading from "~/components/atoms/Heading.vue";
import {useUserStore} from "~/store/userStore";
import {useRoute} from "vue-router";
import Text from "~/components/atoms/Text.vue";
import {useCustomFetch} from '~/composables/customFetch.js';

const userStore = useUserStore()
const errors = ref([])
const route = useRoute()

if (userStore.token) {
  navigateTo('/')
}

const login = (formData) => {
  useCustomFetch('/login', {
    method: 'POST',
    body: formData,
  }, true).then((response) => {
    userStore.setToken(response.token)
    const redirectAfterLogin = route.query.redirect;

    if (redirectAfterLogin) {
      navigateTo(redirectAfterLogin);
    } else {
      navigateTo('/');
    }
  }).catch((error) => {
    if (error.status === 401) {
      if (errors.value.includes(error.statusText)) return
      errors.value.push(error.statusText)
    }
  })
}
</script>

<template>
  <div class="flex-center">
    <div class="row full-width g-24">
      <div v-if="$device.isDesktop">
        <img src="/images/login/login.jpg"/>
      </div>
      <div>
        <div class="form-container center-auto g-64 ">
          <div class="g-16">
            <Heading>Sign In</Heading>
            <Text class="sub-title">Welcome back! Please enter your details</Text>
          </div>
          <Form :errors="errors" @submit="login">
            <template #default>
              <FormInput name="email" :validations="['required','email']" label="Email" type="email"/>
              <FormInput name="password" :validations="['required']" label="Password" type="password"/>
            </template>
            <template #submit>Sign In</template>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
img {
  width: 100%;
}

.sub-title {
  color: #909198;
}

.form-container {
  @include smd {
    padding: 10px;
  }
}
</style>
